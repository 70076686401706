import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthInterface, AuthUserModel } from '../interfaces/interfaces';
import { SettingsProvider } from './settings';
import { BehaviorSubject, Observable, from, map, of } from 'rxjs';
import { HttpClientProvider } from './http-client';
// import { AlertService } from './alert.service';
// import { NotificationService } from './notification.service';
import { getEnvironment } from '../environment/environment';
import { LoadingService } from './loading.service';


@Injectable({
	providedIn: 'root'
})
export class AuthService {

	env = getEnvironment();

	private _user = new BehaviorSubject<AuthUserModel|null>(null);
	user$ = this._user.asObservable();

	get currentUserValue(): AuthUserModel|null {
		return this._user.value;
	}
	set currentUserValue(user: AuthUserModel|null) {
		this._user.next(user);
	}

	headers:any = null;
	
	constructor(
		private httpClient: HttpClient,
		private http: HttpClientProvider,
		private router: Router,
		private loading: LoadingService,
		// private alert: AlertService,
		// private toast: NotificationService,
	) {
	}

	getAuth(): AuthInterface|null {
		let auth:any = localStorage.getItem('auth') || '';
		if(auth) {
			let _auth:AuthInterface = JSON.parse(auth);
			return _auth;
		} else {
			return null;
		}
	}

	get user(): AuthUserModel|null {
		return this.currentUserValue ?? null;
	}

	getToken(): string | null {
		const auth = localStorage.getItem('auth');
		if (auth) {
			try {
				const parsedAuth = JSON.parse(auth);
				return parsedAuth?.access_token || null;
			} catch (e) {
				console.error('Error parsing auth:', e);
				return null;
			}
		}
		return null;
	}

	logout(type?:any) {
		if(this.getToken()) {
			this.loading.loadingShow();
			this.http.postRequest(SettingsProvider.authLogout(), {}).subscribe({
				next:(value) => {
					this.loading.dismiss();
					this.clearData();
				},
				error: (error) => {
					this.loading.dismiss();
					this.clearData();
				}
			});
		} else {
			this.clearData();
		}
		
		if(type&&type == 'expired') {
			// alert('Tu sesión a expirado.');
		}
	}

	clearData() {
		this.currentUserValue = null;
		localStorage.removeItem('auth');
		this.router.navigateByUrl('/auth');
	}

	clearDataWithoutRedirect() {
		this.currentUserValue = null;
		localStorage.removeItem('auth');
	}

	successfullOAuthAuthorization(token:any, expires_in:number, user: any) {
		let expireDate = new Date().getTime() + (1000 * expires_in);
		const auth:AuthInterface = { access_token: token, expires_in: expireDate, user };
		localStorage.setItem('auth', JSON.stringify(auth));
	}

	updateUserStorage(user: AuthUserModel) {
		let auth = localStorage.getItem('auth');
		if(auth) {
			let _auth = JSON.parse(auth);
			const authNew:AuthInterface = { ..._auth, user: user };
			localStorage.setItem('auth', JSON.stringify(authNew));
		}
	}

	getUserByToken(): Observable<AuthUserModel|null> {
		const auth = this.getAuth();
		if (!auth || !auth.access_token) {
			return of(null);
		}
		return this._getUserByToken(auth.access_token).pipe(
			map((result: any) => {
				// console.log(result);
				let _data = result['data'];
				if(_data) {
					let userData:AuthUserModel = _data;
					this.updateUserStorage(userData);
					return this.userData(userData);
				}
				return this.userData(null);
			})
		);
	}

	_getUserByToken(token: string): Observable<any> {
		// return this.httpClient.post(SettingsProvider.getAccount(), {});
		const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
		return this.httpClient.get(SettingsProvider.getAccount(), {
            headers: httpHeaders,
        });
	}

	userData(user: AuthUserModel|null) {
		if (user) {
		  	this.currentUserValue = user;
		} else {
		  	this.logout();
		}
		return user;
	}
}
